<!-- 等级编辑弹窗 -->
<template>
  <el-dialog
    :title="isUpdate?'修改班级':'添加班级'"
    :visible="visible"
    width="500px"
    :destroy-on-close="true"
    :lock-scroll="false"
    @update:visible="updateVisible">
    <el-tooltip class="item" effect="dark" content="Top Left 提示文字" placement="top-start">
      <p style="margin: -10px 0px 16px 27px;color: red">*  标识用来配置前测匹配等级生成试卷用</p>
    </el-tooltip>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="82px">
      <el-row :gutter="15">
        <el-col :sm="12">
          <el-form-item
            label="班级:"
            prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入用班级"
              clearable/>
          </el-form-item>

        </el-col>
        <el-col :sm="12">
          <el-form-item
            label="标识:"
            prop="flag">
            <el-input
              type="number"
              v-model="form.flag"
              placeholder="请输入标识(限数字)"
              clearable/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button @click="updateVisible(false)">取消</el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'LevelEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        name: [
          {required: true, message: '请输入班级', trigger: 'blur'}
        ],
        flag: [
          {required: true, message: '请输入标识', trigger: 'blur'}
        ],
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http[this.isUpdate ? 'put' : 'post']('/Admin/classes/store', this.form).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              this.$message({type: 'success', message: res.data.msg});
              if (!this.isUpdate) {
                this.form = {};
              }
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    }
  }
}
</script>

<style scoped>
</style>
